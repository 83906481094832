<template>
  <div class="container">
    <div class="pt-3 pb-5">
      <OutroCard />
    </div>
    <div class="flex flex-row justify-center pb-5">
      <img src="@/assets/img/logo-grey.svg" width="130" />
    </div>
  </div>
</template>

<script>
import OutroCard from "../components/OutroCard";
export default {
  name: "AssessmentSurveyFinish",
  components: {
    OutroCard,
  },
  mounted() {
    window.addEventListener("popstate", this.listener);
  },

  methods: {
    listener(event) {
      this.$router.push("/");
      window.removeEventListener("popstate", this.listener);
    },
  },
};
</script>
