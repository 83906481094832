<template>
  <div class="w-l mx-auto rounded shadow bg-white">
    <img :src="outroImage" rel="preload" class="w-full"/>
    <div class="p-4">
      <h3 class="font-headers font-bold text-l text-grey-dark-2 mb-4">
        Thank You!
      </h3>
      <RichText :content="assessment.outroText" :editable="false" />
      <router-link :to="{ path: '/' }">
        <Button
          class="w-full mt-8 justify-center"
          size="medium"
          text="Return to Dashboard"
        />
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import defaultOutroImage from "@/assets/img/assessment/outro-image.png";
import RichText from "@/components/RichText/TipTap.vue";
import { downloadSurveyImage } from "../../../services/api/products";

export default {
  name: "IntroCard",
  data: () => ({
    defaultOutroImage,
    outroImage: null,
  }),
  components: { RichText },
  computed: {
    ...mapState({
      assessment: (state) => state.assessments.selectedAssessment,
    }),
    ...mapGetters({
      getText: "assessments/getAssessmentOutro",
    }),
    outroText() {
      return JSON.parse(this.getText);
    },
    outroImageId() {
      return this.assessment?.outroImageId;
    },
  },
  async mounted() {
    if (this.outroImageId) {
      const rsp = await downloadSurveyImage(this.outroImageId);
      this.outroImage = URL.createObjectURL(rsp.data);
    } else {
      this.outroImage = this.defaultOutroImage;
    }
  },
};
</script>

<style lang="scss" scoped>
.overlay {
  top: 0; // HACK: tailwind inset not working
}
.assessment-current {
  left: 50%;
  right: 50%;
  position: absolute;
  top: 48%;
  bottom: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.progress-bar {
  bottom: 12px;
  left: 50%;
  right: 50%;
  transform: translate(-50%, 0);
}
</style>
